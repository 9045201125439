.navbar {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  padding-left: 15px;
  padding-right: 15px;
  top: 0;
  height: $navbar-mobile-height;

  width: 100vw;
  left: 0;

  background: $c-white;
  z-index: 10;
  border-bottom: $border-light;
  box-shadow: 0 4px 8px 4px rgba($color: $c-black, $alpha: 0.06);
  .ustella-branding {
    position: absolute;
    right: 15px;
    width: max-content;
    display: flex;
    align-items: center;

    .logo {
      width: 90px;
    }
  }
  .user_area {
    display: none;
  }

  .container.container-navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .breadcrumb {
      display: none;
    }
  }
}

@media (min-width: $media-lg) {
  .navbar {
    width: calc(100vw - #{$sidebar-desktop-collapsed-width});
    left: auto;
    right: 0;
    height: $navbar-desktop-lg-height;

    .container.container-navbar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .breadcrumb {
        display: block;
        margin-bottom: 0;
      }
    }

    .ustella-branding {
      display: none;
    }

    .user_area {
      display: flex;
      align-items: center;
      user-select: none;

      .user {
        position: relative;
        &.dropdown-active {
          .nav-button {
            background-color: $c-white;
            border: $border-light;
            box-shadow: 0 2px 4px 2px rgba($color: #000000, $alpha: 0.06);
          }

          .user_dropdown {
            display: flex;
          }
        }

        .nav-button {
          border: none;
          background: none;
          position: relative;
          display: flex;
          align-items: center;
          height: calc(100% - 8px);
          cursor: pointer;
          // margin-right: 15px;
          padding-left: 15px;
          border-radius: 6px;
          border: $border-light;
          padding: 5px 3px 7px 20px;
          background-color: $c-white;
          transition: background-color 100ms ease;

          .info {
            margin-top: 2px;
            margin-right: 8px;
            text-align: right;

            .greetings {
              display: block;
              opacity: 0.7;
              font-size: 13px;
              user-select: none;
            }

            .name {
              font-size: 17px;
            }
          }

          .profile_picture {
            user-select: none;
            border-radius: 50px;
            overflow: hidden;
            border: $border-light;
            margin-right: 6px;
            background: white;

            .img {
              width: 36px;
              height: 36px;
            }
          }

          &:hover {
            background-color: rgba($c-primary, 0.06);
          }
        }

        .user_dropdown {
          position: absolute;
          right: 0;
          min-width: 300px;
          display: none;
          flex-direction: column;
          top: calc(#{$navbar-mobile-height} - 3px);
          background: #fff;
          border: $border-light;
          box-shadow: 0 8px 16px 8px rgba($color: #000000, $alpha: 0.06);
          padding: 0px 0;
          border-radius: $border-radius;
          cursor: default;

          i {
            font-size: 17px;
          }

          .list {
            &:not(:last-of-type) {
              border-bottom: $border-light;
            }

            &.logout {
              .item {
                .link {
                  color: $c-danger;
                }
              }
            }

            .item {
              .link {
                cursor: pointer;
                border: none;
                background: none;
                text-align: left;
                display: flex;
                align-items: center;
                width: 100%;
                padding: 13px 12px;
                margin: 6px 0;

                color: $c-black;

                &:hover {
                  text-decoration: none;
                  background: rgba($c-black, 0.04);
                }

                .text {
                  width: 100%;
                  font-weight: 500;
                  font-size: 15px;
                }

                .icon {
                  display: flex;
                  align-items: center;
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $media-xxl) {
  .navbar {
    width: calc(100vw - #{$sidebar-desktop-expanded-width});
  }
}
