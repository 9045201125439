.search-card {
  position: relative;
  border: $border-light;
  border-radius: $border-radius-lg;
  box-shadow: 0 2px 4px 2px rgba($c-black, 0.04);
  background: $c-white;

  .title {
    padding: 15px 15px 5px 15px;

    .title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .divider {
    margin-top: 7px;
    border-bottom: $border-light;
  }

  .filters-container {
    padding: 15px;

    .filter {
      position: relative;
      margin-bottom: 8px;

      label {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &.loading {
    height: 326px;
    background: $c-loader-background;
  }
}
