.container.unit {
  padding-bottom: 35px;

  // Mobile title
  .mobile-title {
    position: fixed;
    top: 0;
    left: 0;
    padding: 9px 3px;
    width: 100vw;
    border-bottom: 1px solid rgba($c-black, 0.1);
    background-color: rgba($c-white, 0.4);
    backdrop-filter: saturate(180%) blur(5px);
    transform: translateY(0);
    transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 4;

    &.show {
      transform: translateY($navbar-mobile-height);
    }

    .title {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-top: 2px;

      .icon {
        margin-right: 6px;
        color: $c-primary;

        i {
          font-size: 18px;
        }
      }
      .text {
        margin: 0;
        padding: 0;
        margin-top: 2px;
        font-size: 18px;
      }
    }
  }

  // Tab
  .units-tab {
    margin-top: -5px;
    display: flex;
    margin-bottom: 15px;

    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    border-bottom: $border-light;

    .tab {
      display: block;
      scroll-snap-align: start;

      &:last-of-type {
        .tab-button {
          margin-right: 15px;
        }
      }

      &.current {
        .tab-button {
          color: rgba($color: $c-black, $alpha: 1);
          border-bottom: 2px solid $c-primary;
        }
      }

      .tab-button {
        border: none;
        background: none;
        padding: 8px 18px 12px 18px;
        display: block;
        width: max-content;
        font-size: 14px;
        font-weight: 500;
        color: rgba($color: $c-black, $alpha: 0.7);
      }
    }
  }

  .unit-card {
    margin-bottom: 30px;
    padding: 15px;
    border: $border-light;
    border-radius: $border-radius-lg;
    background: $c-white;
    box-shadow: 0 2px 4px 2px rgba($c-black, 0.04);

    &.table-card {
      padding: 0;
      position: relative;

      // .title {
      //   padding: 15px;
      // }

      .receipt-button {
        span {
          margin-left: 10px;
        }
      }
      .item-documents-container {
        flex: 0 0 100%; /* Let it fill the entire space horizontally */

        .show-docs-button {
          display: none;
        }

        .docs {
          width: 100%;

          .header {
            display: none;
          }

          .document-type {
            .title {
              display: block;
              margin: 0;
              padding: 0;
              margin-bottom: 5px;
              margin-top: 8px;
            }
            .document-button {
              padding: 8px 10px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
      .table-mobile-area {
        position: absolute;
        display: block;
        width: 100%;
        z-index: 1;
      }

      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 14px;
        border-radius: 20px;
        color: $c-white;
        font-weight: 500;
        // padding: 2px 10px;
        margin-top: 5px;
        font-size: 0;
        height: 16px;
        width: 16px;

        &.pending {
          background: $c-pending;
        }
        &.confirmed {
          background: $c-success;
        }
        &.cancelled {
          background: $c-danger;
        }
      }
    }

    &.current-tenant {
      padding-bottom: 30px;
      .tenant-header {
        padding-bottom: 15px;
        border-bottom: $border-light;
      }
      .title {
        opacity: 0.7;
        margin-bottom: -3px;
        display: block;
        font-size: 13px;
      }
      .data {
        display: block;
        font-weight: 500;
      }

      .divider {
        display: block;
        width: 100%;
        border-top: $border-light;
        margin-top: 15px;
        padding-bottom: 15px;
      }

      .document-type {
        position: relative;
        .title {
          margin-bottom: 4px;
        }
        .documents {
          display: flex;
          flex-wrap: wrap;
          .button {
            display: block;
            width: max-content;
            padding: 5px 10px;
            margin-right: 4px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  .end {
    display: block;
    width: 80px;
    height: 2px;
    margin: 0 auto;
    margin-top: 35px;
    background: rgba($c-black, 0.14);
  }
}

@media (min-width: $media-sm) {
  .container.unit {
    .unit-card {
      &.table-card {
        .status {
          font-size: 12px;
          padding: 2px 10px;
          margin-top: auto;
          height: auto;
          width: max-content;
        }
      }
    }
  }
}

@media (min-width: $media-md) {
  .container.unit {
  }
}

@media (min-width: $media-lg) {
  .container.unit {
    // Tab
    .units-tab {
      background-color: $c-white;
      margin-top: 0;
      margin-left: 13px;
      width: calc(100% - 26px);
      border: $border-light;
      border-radius: $border-radius-lg;

      .tab {
        .tab-button {
          padding: 14px 18px 12px 18px;
          font-size: 15px;
        }
      }
    }

    .mobile-title {
      display: none;
    }

    .unit-card {
      &.table-card {
        .receipt-button {
          padding: 7px 9px;
          width: 40px;
          height: 40px;
          border-radius: $border-radius-xl;
          span {
            display: none;
          }

          &:hover {
            span {
              margin-left: -5px;
              background: $c-primary;
              margin-top: 65px;
              position: absolute;
              display: block;
              padding: 2px 12px;
              font-size: 13px;
              border-radius: 50px;
            }
          }
        }

        .tenant-name {
          .name {
            &:hover {
              + .details {
                display: inline-block;
              }
            }
          }
          .details {
            display: none;
          }
        }

        .item-documents-container {
          display: block;
          &.show-docs {
            .docs {
              opacity: 1;
              pointer-events: all;
              transform: translateY(0);
            }
          }
          .show-docs-button {
            display: block;
            max-width: max-content;
            padding: 5px 12px;
          }

          .docs {
            background: $c-white;
            padding: 15px;
            border-top-left-radius: $border-radius-lg;
            border-top-right-radius: $border-radius-lg;
            border: $border-light;
            box-shadow: 0 0px 10px 5px rgba($color: $c-primary, $alpha: 0.08);
            width: 300px;

            position: fixed;
            right: 15px;
            bottom: 0;
            transform: translateY(150px);
            opacity: 0;
            z-index: 1;
            pointer-events: none;

            transition: opacity 200ms cubic-bezier(0.19, 1, 0.22, 1),
              transform 500ms cubic-bezier(0.19, 1, 0.22, 1);

            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;

              .close {
                height: 30px;
                width: 30px;
              }
            }

            .document-type {
              .title {
                display: block;
                margin: 0;
                padding: 0;
                margin-bottom: 5px;
              }
              .document-button {
                padding: 8px 10px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
