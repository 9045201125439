.form {
  .input_row {
    .input_group {
      width: 100%;
      padding-top: $padding;

      label {
        display: block;
        font-size: 13px;
        font-weight: 500;
        opacity: 0.8;
        margin-bottom: 3px;
        text-align: left;
      }

      &.disabled {
        opacity: 0.6;
      }

      input:not([type="checkbox"]),
      textarea {
        width: 100%;
        padding: $input-padding;
        font-size: $input-font-size;
        background-color: $input-background-color;

        border: $border-light;
        border-radius: $border-radius;
        -webkit-appearance: none; // ios border
        -webkit-border-radius: $border-radius; // ios border

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px #e5e5e5;
        }

        &:-webkit-autofill {
          width: 100%;
          padding: $input-padding;
          font-size: $input-font-size;
          background-color: $input-background-color;
          border: none;
          border: $border-light;
          box-shadow: 0 0 0 2px #e5e5e5;
          -webkit-box-shadow: 0 0 0 1000px $input-background-color inset; // ios background

          &:focus {
            outline: none;
            background-color: $input-background-color;
            -webkit-box-shadow: 0 0 0 1000px $input-background-color inset; // ios background
          }
        }
      }
    }
  }
}

.checkbox__container {
  position: inherit;
  width: max-content;

  .checkbox {
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;

    .label {
      margin-left: 30px;
      font-size: 15px;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: $c-primary;
      }

      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
      padding-right: 1px;
      background-color: #eee;
      color: white;
      border: $border-light;
      border-radius: $border-radius;
      transition: background-color 130ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:hover:not(:chekced) input ~ .checkmark {
      opacity: 0.7;
    }

    &:hover input ~ .checkmark {
      outline: none;
      box-shadow: 0 0 0 1px #e5e5e5;
    }
  }
}

.form__loading {
  position: relative;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 2px;
  overflow: hidden;
  transition: opacity 100ms cubic-bezier(0.075, 0.82, 0.165, 1) 50ms;
  opacity: 0;

  &.show {
    opacity: 1;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -120px;
      top: 0;
      height: 100%;
      width: 120px;
      background: $c-primary;
      animation: formLoader 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
}

.custom__datepicker {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .label {
    display: block;
  }

  .datepicker-custom-input {
    appearance: none;
    display: flex;
    align-items: center;
    padding-left: 8px;
    flex: 1;
    width: 100%;
    min-height: 45px;
    background-color: $c-white;
    border: $border-light;
    font-weight: 600;
    border-radius: $border-radius;
    padding-left: 10px;
    transition: box-shadow 50ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($c-gray_light, 1) !important;
    }

    &::-webkit-calendar-picker-indicator {
      font-size: 1px;
    }

    &::placeholder {
      font-weight: 400;
    }
  }

  .icon {
    pointer-events: none;
    position: absolute;
    right: 10px;
    bottom: 15px;
    font-size: 16px;
    color: $c-primary;
  }

  .custom-calendar {
    border: $border-light;
    border-radius: $border-radius-lg;
    font-family: $primary-font;

    z-index: 10;
    box-shadow: 0 10px 20px 10px rgba($c-black, $alpha: 0.08);

    .react-datepicker {
      &__navigation--next {
        border-left-color: $c-primary;
      }
      &__navigation--previous {
        border-right-color: $c-primary;
      }

      &__month-container {
        width: 100%;
      }

      // Header
      &__header {
        width: 100%;
        min-width: 340px;
        background: transparent;
        border-bottom: $border-light;
      }

      &__current-month {
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &__day-names {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
      }

      &__day-name {
        font-weight: 500;
        opacity: 0.9;
        padding-bottom: 5px;
        font-size: 15px;
        margin: 5;
      }

      &__current-month,
      &__day-name {
        color: inherit;
      }

      // Calendar
      &__month {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &__day {
        font-size: 13px;
        padding: 5px;
        width: 36px;
        margin: 6px 6px;
      }
      &__day--outside-month {
        opacity: 0.3;
      }
      &__day--selected,
      &__day--keyboard-selected {
        background-color: $c-primary;
      }
    }

    .datepicker-custom-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 15px;

      .datepicker-button {
        border: none;
        color: $c-primary;
        background: none;
        font-size: 20px;

        padding: 15px;

        .datepicker-prev-button {
          margin-left: 15px;
        }
        .datepicker-next-button {
          margin-right: 15px;
        }
      }
    }
  }

  .react-datepicker__portal {
    .custom-calendar {
      border: none;
      border-radius: 0px;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      touch-action: manipulation;

      .portal-header {
        display: flex;
        padding-top: 8px;
        justify-content: center;
        .portal-title {
          padding: 0;
          font-size: 14px;
          font-weight: 500;
          opacity: 0.4;
        }
      }

      .portal-cancel {
        display: flex;
        width: 100%;
        justify-content: center;

        .portal-cancel-button {
          width: 100%;
          max-width: 360px;
          margin: 30px 15px 15px 15px;
        }
      }
    }

    .react-datepicker {
      // Header
      &__header {
        min-width: 320px;
        padding-top: 0;
      }

      &__current-month {
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &__day-names {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        max-width: 400px;
        margin: 0 auto;
      }
      &__day-name {
        width: 100%;
      }

      &__current-month,
      &__day-name {
        color: inherit;
      }

      // Calendar
      &__month {
        margin: 0;
        padding: 0;
        padding-top: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        min-width: 320px;
        max-width: 400px;
        margin: 0 auto;
      }
      &__week {
        display: flex;
      }
      &__day {
        font-size: 14px;
        padding: 0;
        margin: 0;
        line-height: unset;
        width: unset;
        flex: 1 0 calc(14.28% - 10px);
        margin: 5px;

        &::before {
          content: "";
          display: block;
          padding-bottom: 30%;
        }
        &::after {
          content: "";
          display: block;
          padding-top: 30%;
        }
      }
      &__day--selected,
      &__day--keyboard-selected {
        background-color: $c-primary;
      }
    }
  }
}

.custom__select {
  display: flex;
  flex-direction: column;
  .custom__select-input {
    &__placeholder {
      font-weight: 400;
    }
    &__control {
      width: 100%;
      min-height: 45px;
      background-color: $c-white;
      border-color: $c-gray_light !important;
      font-weight: 600;
      border-radius: $border-radius;
    }
    &__control--is-focused {
      box-shadow: 0 0 0 3px rgba($c-gray_light, 1) !important;
    }

    &__option--is-focused {
      background-color: rgba($c-primary, 0.1) !important;
    }
    &__option--is-selected {
      background-color: $c-primary !important;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-calendar-picker-indicator {
      font-size: 1px;
    }
  }
}

@media (min-width: $media-md) {
  .date-select {
    .react-datepicker__portal {
      .custom-calendar {
        left: -50px;
      }
    }
  }
}

@media (min-width: $media-lg) {
  .date-select {
    .react-datepicker__portal {
      .custom-calendar {
        left: unset;
      }
    }
  }
}
