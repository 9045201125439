.container.dashboard {
  .header-box {
    .box {
      margin-bottom: 15px;
    }
    .types {
      display: none;
    }
  }

  .card-link {
    cursor: default;
    color: unset;
    &:hover {
      text-decoration: none;
    }
  }

  .loading-card {
    overflow: hidden;
    height: 310px;
    border-radius: $border-radius-xl;
    margin-bottom: 20px;

    &.loading {
      background-color: $c-loader-background;
      border: $border-light;

      &:hover {
        box-shadow: none;
      }
    }
  }

  .card {
    position: relative;
    border-radius: $border-radius-xl;
    overflow: hidden;
    margin-bottom: 20px;

    .image-holder {
      position: relative;
    }
    .image {
      pointer-events: none;
      display: block;
      width: 100%;
      object-fit: cover;
      user-select: none;
      border-top-left-radius: $border-radius-xl;
      border-top-right-radius: $border-radius-xl;
    }

    i {
      color: $c-primary;
    }

    .property {
      &__icon i {
        font-size: 16px;
      }

      &__title-area {
        display: flex;
        align-items: center;
      }
      &__title {
        margin-left: 6px;
        font-weight: 600;
        font-size: 15px;
        font-family: $secondary-font;
        color: #000;
      }

      &__data {
        margin-top: 5px;
      }
      &__data-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 3px;
      }
      &__data-title {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -4%;
        opacity: 0.7;
      }
      &__data-value {
        font-size: 14px;
        color: #000;
        font-weight: 500;
      }
    }
    .location {
      .data-holder {
        &__data {
          line-height: 17px;
        }
      }
    }

    .view-more {
      padding: 0 6px 6px 6px;
      &__button {
        border-radius: $border-radius-xl;
        border-color: rgba(#000, 0.08);
      }
    }

    &__grid {
      .image {
        height: 100px;
        box-shadow: 0 8px 16px 8px rgba($c-primary, 0.04);
      }

      .title {
        position: absolute;
        margin-top: -50px;
        padding: 15px;

        .building-name {
          color: white;
          font-weight: 600;
          font-size: 26px;
          text-shadow: 0 0 40px #000, 0 0 3px rgba(#000, 0.3);
        }
      }

      .location {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-bottom: $border-light;

        .icon {
          margin-top: 4px;
          i {
            font-size: 20px;
          }
        }

        .data-holder {
          margin-left: 7px;

          &__title {
            display: none;
          }
          &__data {
            font-size: 15px;
            letter-spacing: -2%;
            font-weight: 500;
          }
        }
      }

      .properties {
        padding: 6px;
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        .property-holder {
          scroll-snap-align: center;
          padding-right: 6px;
          display: block;
          min-width: 200px;
          max-width: 200px;
        }

        .property {
          padding: 7px;
          border-radius: $border-radius-xl;
          background-color: rgba(#000, 0.03);
          border: 1px solid rgba(#000, 0.04);
        }
      }
    }

    &__list {
      .gradient {
        display: none;
      }
    }
  }

  .page-end {
    display: flex;
    justify-content: center;
    .divider {
      margin-bottom: 30px;
      width: 15%;
      padding-bottom: 15px;
      border-bottom: 1px solid $c-gray_light;
    }
  }
}

@media (min-width: $media-sm) {
  .container.dashboard {
    .loading-card {
      height: 308px;
    }

    .card {
      &__grid {
        .properties {
          display: flex;
          overflow-x: auto;
          .property-holder {
            flex: 1;
            min-width: unset;
            max-width: unset;
            padding-right: 0;
            &:last-of-type {
              .property {
                border-right: none;
              }
            }
          }
          .property {
            padding: 7px 10px 7px 12px;
            background-color: transparent;
            border: none;
            border-radius: 0;
            border-right: $border-light;

            &__data-value {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $media-md) {
  .container.dashboard {
    .loading-card {
      height: 442px;

      &.loading__list {
        height: 162px;
        margin-bottom: 18px;
      }
    }

    .header-box {
      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .types {
        position: relative;
        display: flex;
        border-radius: 50px;
        box-shadow: 0 4px 8px 4px rgba(#000, 0.05);
        border: 1px solid rgba(#000, 0.08);
        background-color: #f8f8f8;

        .type-background {
          position: absolute;
          width: 50%;
          border-radius: 50px;
          pointer-events: none;
          user-select: none;
          height: 100%;
          z-index: 1;
          background-color: white;
          transition: transform 400ms cubic-bezier(0.83, 0, 0.17, 1);
          box-shadow: 0 0 0 1px rgba(#000, 0.08);

          &.left {
            transform: translateX(0);
          }
          &.right {
            transform: translateX(100%);
          }
        }
        .type-button {
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 12px;
          border: none;
          background-color: none;
          background: none;
          border-radius: 50px;
          i {
            font-size: 16px;
          }

          &:first-of-type {
            margin-left: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:last-of-type {
            margin-right: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }

    .card-link {
      cursor: pointer;
    }

    .card {
      box-shadow: 0 2px 4px 2px rgba($c-primary, 0);
      transition: box-shadow 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
      .view-more {
        display: none;
      }

      &:hover {
        box-shadow: 0 4px 8px 4px rgba($c-black, 0.08);
      }

      &__grid {
        .location {
          height: 65px;
        }
        .properties {
          display: flex;
          flex-direction: column;
          .property-holder {
            padding: 7px 10px 2px 12px;
            &:last-of-type {
              .property {
                border-bottom: none;
              }
            }
          }
          .property {
            padding: 0;
            padding-bottom: 7px;
            border-right: none;
            border-bottom: $border-light;
          }
        }
      }

      &__list {
        position: relative;
        $image-width: 140px;
        border-radius: calc(#{$border-radius-xxl} * 1.2);
        margin-bottom: 18px;

        .card-link {
          display: flex;
          flex-direction: row;
        }
        .image-holder {
          position: absolute;
          display: flex;
          padding-right: 0;
          width: $image-width;
          height: 100%;
          .image {
            border-radius: 0;
            border-top-left-radius: $border-radius-xxl;
            border-bottom-left-radius: $border-radius-xxl;
          }
        }
        .content {
          margin-left: $image-width;
          flex: 1;
          .meta {
          }
          .title {
            margin-top: 15px;
            margin-left: 15px;
            .building-name {
              font-size: 20px;
            }
          }
          .location {
            margin: 10px 15px 10px 15px;
            display: flex;
            align-items: center;
            .icon {
              display: none;
            }

            .data-holder {
              &__title {
                font-size: 13px;
                font-weight: 300;
                opacity: 0.6;
                display: block;
              }

              &__data {
                font-weight: 400;
                font-size: 14px;
                letter-spacing: -2%;
              }
            }
          }

          .properties {
            border-top: $border-light;
            padding-right: 10px;
            padding-left: 15px;
            display: flex;
            min-width: 450px;
            .property-holder {
              flex: 1;
              .property {
                padding: 7px 7px 7px 12px;
                border-right: $border-light;
              }

              &:first-of-type {
                .property {
                  padding-left: 0;
                }
              }

              &:last-of-type {
                .property {
                  border-right: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $media-lg) {
  .container.dashboard {
    .loading-card {
      height: 264px;

      &.loading__list {
        height: 102px;
      }
    }

    .card {
      &__grid {
        .properties {
          flex-direction: row;
          padding: 6px;
          .property-holder {
            flex: 1;
            min-width: unset;
            max-width: unset;
            padding: 0;
            &:last-of-type {
              .property {
                border-right: none;
              }
            }
          }
          .property {
            padding: 7px 10px;
            border-right: $border-light;
            border-bottom: none;
          }
        }
      }

      &__list {
        $image-width: 140px;
        display: block;
        min-height: 100px;

        .image-holder {
          width: $image-width;
        }

        .card-link {
          position: relative;
          width: 100%;
          display: flex;
          min-height: inherit;
          height: 100%;
        }
        .content {
          margin-left: $image-width;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 100%;
          min-height: inherit;
          width: 100%;

          .meta {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: inherit;
          }
          .location {
            padding-bottom: 3px;
            height: auto;
          }
          .properties {
            border-top: none;
            min-height: 100%;
            flex: 1;
            max-width: 600px;
            padding-right: 0;
            border-left: $border-light;
            .property-holder {
              display: flex;
              align-items: center;
              min-height: 100%;
              border-right: $border-light;

              &:last-of-type {
                border-right: none;
              }

              .property {
                padding: 7px 12px;
                flex: 1;
                border-right: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $media-xxl) {
  .container.dashboard {
    .card {
      &__list {
        $image-width: 200px;

        .image-holder {
          width: $image-width;
        }

        .content {
          margin-left: $image-width;
        }
      }
    }
  }
}
