// Disable smooth scroll
* {
  scroll-behavior: unset !important;
}

// Fonts
$primary-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol" !default;

// $secondary-font: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
//   "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
//   "Segoe UI Symbol" !default;

$secondary-font: "Gilroy";
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $secondary-font;
}

// Colors
$c-primary: #3b4cb8;
$c-secondary: #6f35c4;
$c-pitch_black: #000000;
$c-black: #1e1e1e;
$c-body: #333333;
$c-white: #ffffff;
$c-gray_dark: #5c5d5f;
$c-gray: #79787d;
$c-gray_light: #e6e5eb;
$c-gray_lighter: #ecebef;
$c-gray-background: #f1f1f1;
$c-loader-background: #efeef4;
$c-danger: #dc3545;
$c-success: #198754;
$c-revenue: #3bde5f;
$c-pending: #fd7e14;
$c-error: #e74943;
$c-delete: #bd464c;

// Media Queries
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;
$media-xxl: 1500px;

// Container widths
$container-max-widths: (
  sm: $media-sm,
  // Default 540px
  md: $media-md,
  // Default 720px
  lg: $media-lg,
  // Default 960px
  xl: $media-xl,
  // Default 1140px
  xxl: $media-xxl,
  // Default 1320px
);

// Sidebar
$sidebar-mobile-width: 300px;
$sidebar-tablet-width: 400px;
$sidebar-desktop-collapsed-width: 107px;
$sidebar-desktop-expanded-width: 195px;

// Navbar
$navbar-mobile-height: 60px;
$navbar-desktop-lg-height: 80px;
$navbar-desktop-xl-height: 90px;

// Buttons
$c-button-hover: #f2f3f5;
$c-button-active: #d7dbe4;

// Borders
$border-radius-sm: 3px;
$border-radius: 4px;
$border-radius-lg: 6px;
$border-radius-xl: 8px;
$border-radius-xxl: 12px;
$border-light: 1px solid $c-gray_light;
$border-lighter: 1px solid $c-gray_lighter;
$border-dark: 1px solid $c-gray_dark;

// Padding
$padding: 15px;

// Form
$input-padding: 15px 16px;
$input-font-size: 16px;
$input-background-color: #f9f9f9;

// Utils
$skeleton-width: 240px;
