.container.breadcrumb-container {
  .breadcrumb-list {
    display: flex;

    .breadcrumb-item {
      position: relative;

      .breadcrumb-subtitle {
        display: block;
        font-size: 12px;
        margin-bottom: -7px;
        opacity: 0.7;
      }

      &::before {
        content: "";
      }
      &:not(:last-of-type) {
        margin-right: 14px;
        padding-right: 14px;

        &::before {
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          width: 1px;
          height: 50%;
          border-right: 1px solid #acacac;
          transform: translateY(-2px) translateX(5px) rotate(27deg);
        }
      }
    }
    .breadcrumb-text {
      font-size: 14px;
      &.breadcrumb-current {
        font-weight: 600;
      }
    }
  }

  &.breadcrumb-mobile {
    background-color: $c-white;
    margin-top: -16px;
    margin-bottom: 10px;
    padding: 8px 15px;
    min-height: 25px;
    border-top: $border-light;
    border-bottom: $border-light;
  }

  &.breadcrumb-desktop {
    .breadcrumb-list {
      display: none;
    }
  }
}
@media (min-width: $media-sm) {
  .container.breadcrumb-container {
    &.breadcrumb-mobile {
      border: $border-light;
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
    }
  }
}

@media (min-width: $media-lg) {
  .container.breadcrumb-container {
    &.breadcrumb-desktop {
      .breadcrumb-list {
        display: flex;
      }
    }

    .breadcrumb-list {
      .breadcrumb-subtitle {
        display: block;
        font-size: 13px;
        margin-bottom: -4px;
      }
      .breadcrumb-text {
        font-size: 18px;
        &.breadcrumb-current {
          font-weight: 600;
        }
      }
    }

    &.breadcrumb-mobile {
      display: none;
    }
  }
}
