@keyframes flashingLogo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.app-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $c-white;
  z-index: 9999;

  .logo {
    user-select: none;
    pointer-events: none;
    height: 120px;
    margin-top: -170px;
    opacity: 1;
    animation: flashingLogo 1.5s ease-out infinite;
  }
}

@media (min-width: $media-md) {
  .app-loader {
    background: $c-gray-background;
    .logo {
      height: 140px;
      margin-top: -70px;
    }
  }
}
