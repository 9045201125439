.container.not-found {
  .not-found__card {
    margin-top: 15px;

    .header {
      text-align: center;
      .code {
        user-select: none;
        pointer-events: none;
        font-size: 110px;
        color: $c-black;
        font-weight: 900;
        opacity: 0.2;
      }
      .title {
        color: $c-black;
        opacity: 0.8;
      }
    }
    .go-back {
      max-width: 600px;
      margin: 0 auto;
      margin-top: 60px;

      .description {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        text-align: center;
      }

      .route {
        margin: 0 auto;
        max-width: 400px;
        margin-top: 20px;
      }
    }
  }
}

@media (min-width: $media-lg) {
  .container.not-found {
    .not-found__card {
      .header {
        margin-top: 30px;
        .code {
          font-size: 180px;
        }
        .title {
          margin-top: -20px;

          opacity: 1;
        }
      }
      .go-back {
        margin-top: 60px;

        .description {
          font-size: 14px;
          line-height: 19px;
          font-weight: 500;
          text-align: center;
        }

        .route {
          margin: 0 auto;
          max-width: 300px;
          margin-top: 20px;
        }
      }
    }
  }
}
