@keyframes formLoader {
  from {
    left: -120px;
  }
  to {
    left: 100%;
  }
}

@keyframes skeletonLoader {
  from {
    left: -$skeleton-width;
  }
  to {
    left: 100%;
  }
}
