.custom_table-container {
  .quick-search {
    margin-top: 15px;
    .full-screen {
      display: none;
    }
  }

  .custom_table {
    width: 100%;
    table-layout: fixed;

    .tr {
      &__header {
        border-bottom: 2px solid $c-gray_light;
        .hidden__mobile {
          display: none;
        }

        th {
          padding: 17px 15px;
          letter-spacing: -1px;
          font-weight: 600;
          font-size: 15px;
        }
      }

      &__items {
        &.filler {
          display: table-row;
          height: 66px;
        }

        cursor: pointer;
        border-bottom: $border-light;

        .hidden__mobile {
          display: none;
        }

        .table-cell {
          position: relative;

          border-right: $border-light;

          &.null-cell {
            opacity: 0.3;
            user-select: none;
          }

          .cell-content {
            padding: 15px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            word-break: break-all;
          }

          .overflow-cell-tooltip {
            display: none;
          }
          &:last-of-type {
            border-right: none;
          }
        }

        &:hover {
          background: rgba($c-gray_light, 0.4);
        }

        &.mobile_area-shown {
          box-shadow: 0 10px 20px 10px rgba($c-primary, 0.15);
          background: rgba($c-gray_light, 0.4);
        }
      }
    }

    .mobile_area {
      position: absolute;
      width: 100%;
      z-index: 1;

      box-shadow: -10px 10px 10px rgba($c-primary, 0.07),
        10px 10px 10px rgba($c-primary, 0.07),
        inset 0px 5px 10px 1px rgba($c-gray_light, 0.7);

      border-bottom: $border-light;

      background: $c-white;
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
    }
  }

  .tr_loading {
    position: relative;

    padding-top: 55px;
    width: 100%;

    .td_loader {
      position: relative;

      height: 50px;
      width: 100%;
      background-color: $c-loader-background;

      border-top: $border-light;
    }
  }

  .no-match {
    position: relative;
    border-bottom: $border-light;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
  }

  .pagination {
    padding: 15px;
    display: flex;

    .page-button {
      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      background: none;
      margin-right: 15px;
      height: 40px;
      width: 40px;
      border-radius: $border-radius-lg;
      border: $border-light;
      font-weight: 500;
      color: $c-gray_dark;

      &:hover {
        box-shadow: 0 0 0 3px rgba($c-primary, 0.12);
      }

      &.current {
        background: $c-primary;
        color: $c-white;
        border: 1px solid transparent;

        &:hover {
          opacity: 0.85;
        }
      }

      &:disabled {
        background: $c-gray_light;
        cursor: default;
        &:hover {
          opacity: 1;
          box-shadow: none;
        }
      }
    }
  }

  .export {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    .export_button {
      padding: 7px 15px;

      .export_icon {
        color: $c-primary;
        margin-right: 5px;
      }
    }
  }
}

@media (min-width: $media-md) {
}

@media (min-width: $media-lg) {
  .custom_table-container {
    &.fullscreen {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9999;
      width: 100vw;
      height: 100vh;
      background: $c-white;
    }

    .quick-search {
      margin-top: unset;
      display: flex;
      .full-screen {
        display: block;
        margin-left: 8px;
      }
    }

    .custom_table {
      .mobile_area {
        display: none;
      }

      .tr {
        &__header {
          .hidden__mobile {
            display: table-cell;
          }
          .hidden__desktop {
            display: none;
          }

          th {
            padding: 15px;
            letter-spacing: 0px;
            font-weight: 500;
            font-size: 16px;

            // text-overflow;
          }
        }

        &__items {
          .cell-content {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            word-break: break-all;
            font-size: 15px;

            &:hover {
              + .overflow-cell-tooltip {
                display: block;
              }
            }
          }

          .overflow-cell-tooltip {
            position: absolute;
            z-index: 1;
            font-size: 13px;
            margin-left: 6px;
            margin-top: -12px;
            width: max-content;
            max-width: 80vw;
            display: none;
            background: $c-white;
            padding: 4px 8px;
            border: $border-light;
            border-radius: $border-radius-lg;

            &.stay-open {
              display: block;
            }
          }
        }
      }

      &__items {
        .hidden__mobile {
          display: table-cell;
        }
      }
    }
  }
}
