// ABSTRACT
@import "./abstract/imports";
@import "./abstract/variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";

// BASE
@import "./base/base";
@import "./base/buttons";
@import "./base/animations";
@import "./base/typography";
@import "./base/utilities";

// LAYOUTS
@import "./layouts/navbar";
@import "./layouts/breadcrumb";
@import "./layouts/sidebar";
@import "./layouts/skeleton-loader";

// PAGES
@import "./pages/dashboard";
@import "./pages/maintenance";
@import "./pages/properties";
@import "./pages/unit";
@import "./pages/reporting";
@import "./pages/homepage";
@import "./pages/auth";
@import "./pages/not_found";

// COMPONENTS
@import "./components/forms";
@import "./components/datatable";
@import "./components/better_datatable";
@import "./components/spinner";
@import "./components/sign-out_modal";
@import "./components/app-loader";
@import "./components/seach_card";
