.loading {
  .loader {
    position: relative;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: opacity 100ms cubic-bezier(0.075, 0.82, 0.165, 1) 50ms;
    opacity: 1;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -$skeleton-width;
      top: 0;
      height: 100%;
      width: $skeleton-width;
      background: linear-gradient(
        90deg,
        rgba($c-white, 0) 0%,
        rgba($c-white, 0.4) 30%,
        rgba($c-white, 0.7) 70%,
        rgba($c-white, 0) 100%
      );

      animation: skeletonLoader 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
}
