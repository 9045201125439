$graph-revenue: #54c46c;
$graph-expenses: #bd6f6f;

.container.properties {
  padding-bottom: 70px;
  // Tab
  .properties-tab {
    margin-top: -5px;
    margin-bottom: 15px;
    display: flex;

    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    border-bottom: $border-light;

    .tab {
      display: block;
      scroll-snap-align: start;

      &:last-of-type {
        .tab-button {
          margin-right: 15px;
        }
      }

      &.current {
        .tab-button {
          color: rgba($color: $c-black, $alpha: 1);
          border-bottom: 2px solid $c-primary;
        }
      }

      .tab-button {
        border: none;
        background: none;
        padding: 8px 18px 12px 18px;
        display: block;
        width: max-content;
        font-size: 14px;
        font-weight: 500;
        color: rgba($color: $c-black, $alpha: 0.7);
      }
    }
  }

  // Mobile title
  .mobile-title {
    position: fixed;
    top: 0;
    left: 0;
    padding: 9px 3px;
    width: 100vw;
    border-bottom: 1px solid rgba($c-black, 0.1);
    background-color: rgba($c-white, 0.4);
    backdrop-filter: saturate(180%) blur(5px);
    transform: translateY(0);
    transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 4;

    &.show {
      transform: translateY($navbar-mobile-height);
    }

    .items {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-top: 2px;

      .icon {
        margin-right: 6px;
        color: $c-primary;

        i {
          font-size: 18px;
        }
      }
      .text {
        margin: 0;
        padding: 0;
        margin-top: 2px;
        font-size: 18px;
      }
    }
  }

  // Time range
  .time-range {
    position: fixed;
    top: $navbar-mobile-height;
    right: 0;
    width: 170px;
    z-index: 10;
    padding: 4px 10px;

    &__inputs {
      display: flex;
    }
    &__input_holder {
      flex: 0 0 50%; /* flex-grow, flex-shrink, flex-basis */
    }

    &__input_holder__year {
      flex: 4;
    }
    &__input_holder__month {
      flex: 5;
    }

    &__input_month__indicator,
    &__input_year__indicator,
    &__input_month__indicator-separator,
    &__input_year__indicator-separator {
      display: none;
    }

    &__input_year,
    &__input_month {
      // Input area
      &__control {
        cursor: pointer;
        border-radius: 0px;
        border-color: $c-gray_light;
        // font-size: 14px;
        font-weight: 500;
      }
      &__control--is-focused {
        box-shadow: none;
        background-color: #f0f0f0;
      }

      // Options area
      &__menu {
        box-shadow: 0 0 0 1px solid $c-gray_light;
      }
      &__option {
        cursor: pointer;
        font-size: 13px;
        padding: 6px 8px;
        &:active {
          background-color: rgba($c-primary, 0.12);
        }
      }
      &__option--is-focused {
        background-color: rgba($c-primary, 0.05);
      }
      &__option--is-selected {
        background-color: $c-primary;
      }
    }

    &__input_year {
      &__control {
        border-left: none;
        border-top-right-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
      }
    }
    &__input_month {
      &__control {
        border-top-left-radius: $border-radius-lg;
        border-bottom-left-radius: $border-radius-lg;
      }
    }
  }

  // Chart
  .property-chart {
    padding: 15px;
    border: $border-light;
    border-radius: $border-radius-lg;
    background: $c-white;
    box-shadow: 0 2px 4px 2px rgba($c-black, 0.04);

    .chart-content {
      display: flex;
      .chart {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        margin-top: 15px;

        .occupancy-percentage {
          z-index: 0;
          position: absolute;
          font-weight: 600;
          opacity: 0.8;
        }

        .doughnut {
          z-index: 0;
        }
      }

      .labels {
        width: 100%;

        .labels-list {
          margin-left: 20px;
          border: $border-light;
          border-radius: $border-radius;
          overflow: hidden;

          .label-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: rgba($c-white, 1);
            transition: background-color 250ms
              cubic-bezier(0.215, 0.61, 0.355, 1);

            padding: 6px 12px;

            border-bottom: $border-light;
            &:last-of-type {
              border-bottom: none;
            }

            width: 100%;
            &.selected {
              background-color: rgba($c-gray_light, 0.4);
              transition: background-color 100ms
                cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            .property {
              font-weight: 500;
              .property-count {
                display: block;
                font-size: 12px;
                margin-bottom: -5px;
                opacity: 0.6;
                font-weight: 400;
              }
            }

            .percentage {
              margin-top: 3px;
              font-size: 17px;
              font-weight: 500;
            }
          }
        }
      }
    }

    &.loading {
      padding: 0;
      height: 207px;
      background: $c-loader-background;
    }
  }

  // Finance card
  .finance-card {
    padding: 15px;
    border: $border-light;
    border-radius: $border-radius-lg;
    background: $c-white;
    box-shadow: 0 2px 4px 2px rgba($c-black, 0.04);

    .page-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &.revenue {
      .icon i {
        color: #198754;
      }
    }

    &.expense {
      .icon i {
        color: #e74943;
      }
    }

    .data {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.all-time {
        margin-top: 4px;
        .datespan {
          margin-top: 3px;
          margin-bottom: 0;
          font-size: 14px;
          .dynamic {
            font-size: 16px;
            margin-top: 5px;
          }
        }
        .value {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .datespan {
        font-size: 15px;
        display: flex;
        flex-direction: column;
        margin: 8px 0;
        .static {
          font-size: 13px;
          font-weight: 400;
          margin-bottom: -3px;
          opacity: 0.7;
        }
        .dynamic {
          font-weight: 600;
        }
      }
      .value {
        font-weight: 600;
        font-size: 15px;
      }
    }
  }

  // Overview
  .overview-data {
    // margin-top: 15px;

    &.loading {
      height: 300px;
      background: $c-loader-background;
    }

    .page-title {
      .title {
        padding-top: 8px;
      }
    }
  }

  .data-box {
    margin-bottom: 15px;
    align-items: center;
    padding: 15px 12px;
    border: $border-light;
    border-radius: $border-radius-lg;
    background: $c-white;
    box-shadow: 0 2px 4px 2px rgba($c-black, 0.04);

    &.no-padding {
      padding: 0;
    }
  }

  // Unit details
  .unit-details {
    font-size: 15px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: $border-light;
      padding: 12px 15px;

      .title {
        margin-top: 4px;
      }

      .total-units {
        font-size: 17px;

        &__title {
          opacity: 0.7;
        }

        &__data {
          font-weight: 600;
        }
      }
    }
    .categories {
      background-color: rgba($c-gray-background, 1);
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;

      display: inline-flex;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      width: 100%;

      .section-divider {
        margin-top: 12px;
        background-color: $c-gray_light;
        opacity: 1;
      }

      .category {
        display: inline-table;
        scroll-snap-align: center;
        width: 80vw;
        margin-right: 10px;

        &__content {
          border-right: $border-light;
          border-left: $border-light;
          background-color: $c-white;
          padding: 8px 0 12px 0px;
        }

        &:first-of-type {
          border-left: none;
        }

        &__section {
          padding: 0 15px;
        }

        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            .icon {
              color: $c-primary;
              font-size: 18px;
            }
            .text {
              font-size: 18px;
            }
          }

          .occupancy {
            padding-top: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .title {
              font-size: 13px;
              opacity: 0.6;
              margin-bottom: -5px;
            }
            .data {
              font-weight: 600;
              font-size: 16px;
            }
          }
        }

        &__counts {
          display: flex;
          flex-direction: column;

          .count-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border: $border-light;
            border-radius: $border-radius;
            padding: 12px 13px;
            margin-bottom: 4px;
            background: none;

            &:disabled {
              cursor: default;
            }

            .data {
              font-size: 16px;
              font-weight: 500;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            &:not(:disabled):hover {
              background-color: rgba($c-black, 0.05);
            }

            &:not(:disabled):focus,
            &:not(:disabled):active {
              background-color: rgba($c-primary, 0.05);
              box-shadow: 0 0 0 2px rgba($c-primary, 0.09);
            }
          }
        }

        &__financial {
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            &:last-of-type {
              margin-bottom: 0px;
            }
            .data {
              font-weight: 500;
            }
          }
        }

        .item-loading {
          // display: block;
          background-color: $c-gray_light;
          border-radius: $border-radius-xl;
          color: transparent;
        }
      }
    }
  }

  // Revenue overview
  .revenue-overview {
    font-size: 15px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: $border-light;
      padding: 12px 15px;

      .title {
        margin-top: 4px;
      }
    }
    .total,
    .dynamic {
      padding: 12px 15px;
      border-bottom: $border-light;

      .item {
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0px;
        }
        .subtitle {
          font-size: 12px;
          display: block;
          opacity: 0.6;
          font-weight: 300;
          margin-bottom: -3px;
        }
      }

      .data {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .item-loading {
      background-color: $c-gray_light;
      border-radius: $border-radius-xl;
      color: transparent;
    }

    &.revenue {
      .icon i {
        color: $c-success;
      }
    }

    &.expense {
      .icon i {
        color: $c-error;
      }
    }
  }

  // Datatable
  .property-datatable {
    margin-bottom: 50px;
    border: $border-light;
    border-radius: $border-radius-lg;
    background: $c-white;
    box-shadow: 0 2px 4px 2px rgba($c-black, 0.04);
  }

  // Graph
  .graph-holder {
    height: 100%;

    .graph-content {
      padding: 15px;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;

      .graph {
        .title {
          display: block;
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 15px;
          color: $c-pitch_black;
        }
      }
      .graph-buttons {
        display: none;
      }

      .graph-area {
        position: relative;
        margin-right: 10px;
        margin-left: 35px;
        flex: 1;
        display: flex;
        flex-direction: column;
        border-left: 2px solid rgba($c-black, 0.09);

        &.monthly {
          margin-bottom: 40px;
        }
        &.yearly {
          margin-bottom: 20px;
        }
      }

      .graph-box-holder {
        display: flex;
        align-items: center;
        margin: 10px 0;

        &.revenue {
          margin-bottom: 5px;

          .graph-box {
            background-color: $graph-revenue;
          }
        }
        &.expenses {
          margin-top: 5px;

          .graph-box {
            background-color: $graph-expenses;
          }
        }
      }
      .graph-label {
        position: absolute;
        left: -37px;
        font-size: 12px;
        opacity: 0.9;
        font-weight: 500;
      }

      .graph-box {
        position: relative;
        display: flex;
        align-items: center;
        height: 30px;
        margin-left: 1px;
        transition: width 600ms cubic-bezier(0.87, 0, 0.13, 1);
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        .graph-value {
          white-space: nowrap;
          position: absolute;
          left: 5px;
          display: flex;
          color: white;
          background-color: rgba($c-black, 0.3);
          padding: 2px 8px;
          border-radius: 15px;
          font-size: 11px;
          opacity: 1;
        }
      }

      .graph-lines {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        pointer-events: none;

        .line {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          background-color: rgba($c-black, 0.07);
          width: 1px;
          height: 100%;

          span {
            pointer-events: all;
            display: block;
            font-size: 11px;
            opacity: 0.6;
            bottom: -20px;
            position: absolute;
          }
        }
      }
    }
  }

  .end {
    display: block;
    width: 80px;
    height: 2px;
    margin: 0 auto;
    background: rgba($c-black, 0.14);
  }
}

@media (min-width: $media-sm) {
  .container.properties {
    // Unit details
    .unit-details {
      .categories {
        .category {
          width: 400px;

          &:last-of-type {
            width: 400px;
          }
        }
      }
    }
  }
}

@media (min-width: $media-md) {
  .container.properties {
    // Unit details
    .unit-details {
      border-radius: $border-radius-lg;
      overflow: hidden;
      .categories {
        background-color: $c-white;
        display: flex;
        overflow-x: visible;

        .section-divider {
          opacity: 0.7;
          margin: 8px 0;
        }

        .category {
          display: block;
          width: 100%;
          flex: 1;
          margin-left: 0;
          margin-right: 0;
          // border-right: 3px solid $c-gray_light;
          padding: 10px 5px;

          &:first-of-type {
            padding-left: 10px;
          }

          &:last-of-type {
            padding-right: 10px;
          }

          &__header {
            margin-bottom: 5px;
            .title {
              .icon {
                font-size: 17px;
              }
              .text {
                margin-top: 3px;
                font-size: 17px;
              }
            }

            .occupancy {
              .title {
                font-size: 13px;
                opacity: 0.5;
                font-weight: 300;
                margin-bottom: -5px;
              }
            }
          }

          &__content {
            border: none;
            border: $border-light;
            border-radius: $border-radius-lg;
          }

          &__counts {
            padding: 0 6px;
            display: flex;
            flex-direction: column;

            .count-button {
              border: 1px solid transparent;
              padding: 5px 8px;
              margin-bottom: 2px;
              color: $c-black;

              &:disabled {
                cursor: default;
                opacity: 0.5;
              }

              .title {
                font-size: 14px;
                font-weight: 300;
                opacity: 0.85;
              }

              .data {
                font-size: 16px;
                font-weight: 500;
                opacity: 1;
              }

              &:last-of-type {
                margin-bottom: 0;
              }

              &:not(:disabled):hover {
                border: $border-light;
                background-color: rgba($c-black, 0.05);
              }

              &:not(:disabled):focus,
              &:not(:disabled):active {
                border: $border-light;
                background-color: rgba($c-primary, 0.05);
                box-shadow: 0 0 0 2px rgba($c-primary, 0.09);
              }
            }
          }

          &__financial {
            .item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              color: $c-black;

              &:last-of-type {
                margin-bottom: 0px;
              }
              .title {
                font-size: 14px;
                font-weight: 300;
                opacity: 0.85;
              }
              .data {
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    // Revenue overview
    .revenue-overview {
      .total,
      .dynamic {
        display: flex;
        flex-direction: column;

        .item {
          flex: 1;
        }
      }
      .total {
        border-bottom: none;
      }
    }

    // Graph
    .graph-holder {
      .graph-content {
        border-top: $border-light;
        flex-direction: row;
      }
      .graph {
        width: 50%;
        margin-right: 30px;
      }
    }
  }
}

@media (min-width: $media-lg) {
  .container.properties {
    position: relative;

    // Tab
    .properties-tab {
      background-color: $c-white;
      margin-top: 0;
      margin-left: 13px;
      width: calc(100% - 26px);
      border: $border-light;
      border-radius: $border-radius-lg;

      .tab {
        .tab-button {
          padding: 14px 18px 12px 18px;
          font-size: 15px;
        }
      }
    }

    .property-chart {
      margin-top: 0;
      min-height: 216px;
    }

    // Unit details
    .unit-details {
      .categories {
        .section-divider {
          margin: 15px 0;
        }
        .category {
          &__financial {
            margin-bottom: 8px;
          }
        }
      }
    }

    // Time range
    .time-range {
      position: absolute;
      right: 10px;
      top: -7px;
      width: 300px;
      margin-top: 10px;
      z-index: 1;

      &__input_year,
      &__input_month {
        &__control {
          &:hover {
            background-color: rgba($c-black, 0.04);
          }
        }
        &__control--is-focused {
          background-color: $c-white;
          box-shadow: 0 3px 6px 3px rgba($c-black, 0.09);

          &:hover {
            background-color: rgba($c-black, 0.01);
          }
        }
      }
    }

    // Tab
    .properties-tab {
      overflow-x: hidden;
    }

    .overview-data {
    }

    .data-box {
      margin-bottom: 5px;
      padding: 15px;

      .financial {
        .pie-box {
          margin-top: 15px;
        }
      }

      .unit-stats {
        .stats-box {
          margin-top: 15px;
        }
        .item {
          margin: 24px 0;
        }
      }
    }

    .mobile-title {
      display: none;
    }

    .revenue-overview {
      .data-holder {
        border-right: $border-light;
        padding-right: 0;
      }

      &.expense,
      &.revenue {
        .data-holder {
          border-right: none;
          padding-right: 12px;
        }
      }
    }

    // Graph
    .graph-holder {
      .graph-content {
        border-top: none;
        padding-left: 0;
      }
    }
  }
}

@media (min-width: $media-xl) {
  .container.properties {
    // Revenue overview
    .revenue-overview {
      .total,
      .dynamic {
        flex-direction: column;

        .item {
          flex: 1;
          margin-right: 0;
        }

        .data {
          justify-content: space-between;

          .key {
            margin-right: 0;
          }
        }
      }

      .data {
        justify-content: space-between;

        .key {
          margin-right: 0;
        }
      }

      .total {
        border-bottom: $border-light;
      }

      .data-holder {
        border-right: none;
        padding-right: 12px;
      }
    }

    // Unit details
    .unit-details {
      .categories {
        .section-divider {
          margin: 15px 0;
        }
        .category {
          &__financial {
            margin-bottom: 0px;
          }
        }
      }
    }

    // Graph
    .graph-holder {
      .graph-content {
        border-top: none;
        padding-left: 0;
        flex-direction: column;
        padding: 15px;
        margin-top: -7px;

        .graph {
          width: 95%;
          .title {
            display: none;
          }
          .graph-area {
            margin-bottom: 15px;
          }

          display: none;
          &.show {
            display: block;
          }
        }
        .graph-buttons {
          display: flex;
          margin-bottom: 5px;

          .graph-button {
            border: none;
            background: none;
            margin-right: 5px;
            opacity: 0.3;
            font-weight: 500;

            &.active {
              opacity: 1;
              &:hover {
                opacity: 1;
              }
            }

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $media-xxl) {
  .container.properties {
    // Revenue overview
    .revenue-overview {
      .total,
      .dynamic {
        display: flex;
        flex-direction: row;

        .item {
          margin-right: 15px;
        }

        .data {
          justify-content: flex-start;

          .key {
            margin-right: 15px;
          }
        }
      }

      .total {
        border-bottom: none;
      }

      .data {
        justify-content: flex-start;

        .key {
          margin-right: 15px;
        }
      }
      &.revenue,
      &.expense {
        min-height: 190px;
      }
    }

    // Graph
    .graph-holder {
      .graph-content {
        border-top: none;
        padding-left: 0;
        flex-direction: column;
        padding: 15px;
        margin-top: 0px;

        .graph-buttons {
          display: flex;
          margin-bottom: 15px;
        }

        .graph-box-holder {
          margin: 15px 0;

          &.revenue {
            margin-bottom: 7px;
          }
          &.expenses {
            margin-top: 7px;
          }
        }

        .graph-box {
          height: 34px;
        }
      }
    }

    .property-chart {
      margin-top: 0;
      min-height: 140px;
    }
  }
}
