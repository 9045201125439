*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  scroll-behavior: initial;
}
body {
  min-width: 320px;
  overflow-y: scroll;
  background: $c-gray-background;
}

.wrapper {
  // min-height: calc(100vh - (#{$navbar-mobile-height} + 15px));

  &.auth {
    min-height: 100vh;
  }

  &.dashboard {
    margin-top: calc(#{$navbar-mobile-height} + 15px);
  }
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

// bootstrap icons
i[class^="bi-"] {
  font-size: 24px;
  margin: 0;
  line-height: 0;
}

.logo,
i {
  user-select: none;
}

@media (min-width: $media-md) {
  body {
  }
}

@media (min-width: $media-lg) {
  .wrapper {
    min-height: calc(100vh - (#{$navbar-desktop-lg-height} + 15px));

    &.dashboard {
      margin-top: calc(#{$navbar-desktop-lg-height} + 15px);
      margin-left: $sidebar-desktop-collapsed-width + 15px;
      width: calc(100% - (#{$sidebar-desktop-collapsed-width} + 30px));
    }
  }
}

@media (min-width: $media-xxl) {
  .wrapper {
    min-height: calc(100vh - (#{$navbar-desktop-xl-height} + 15px));
    &.dashboard {
      margin-top: calc(#{$navbar-desktop-xl-height} + 15px);
      margin-left: $sidebar-desktop-expanded-width + 15px;
      width: calc(100% - (#{$sidebar-desktop-expanded-width} + 30px));
    }
  }
}
