.sidebar {
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 11;
  background-color: rgba($color: $c-black, $alpha: 0);
  transition: background-color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

  .sidebar__toggler {
    pointer-events: all;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $navbar-mobile-height;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    .toggler_button {
      margin-left: 4px;
      margin-top: 1px;
      border: none;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius;
      height: 48px;
      width: 48px;
      padding-right: 1px;

      &:focus,
      &:active,
      &:hover {
        outline: none;
        background: $c-button-hover;
      }

      &:focus {
        border: $border-light;
      }
    }
  }

  &.show {
    pointer-events: all;
    background-color: rgba($color: $c-black, $alpha: 0.4);

    .content-holder {
      transform: translateX(0);
      transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    }
  }

  .content-holder {
    position: relative;
    width: $sidebar-mobile-width;
    transform: translateX(-$sidebar-mobile-width);
    padding-right: 15px;
    padding-bottom: 120px;
    min-height: 100vh;
    overflow-y: scroll;
    transition: transform 0.3s cubic-bezier(0.83, 0, 0.17, 1);
    background: $c-white;

    .content {
      padding-top: $navbar-mobile-height;
      padding-left: 15px;

      .ustella-branding {
        display: none;
      }

      .user {
        margin-top: 12px;
        padding-bottom: 15px;
        border-bottom: $border-light;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .username {
          .greetings {
            font-size: 13px;
            opacity: 0.7;
            display: block;
          }

          .name {
            font-size: 23px;
            line-height: 23px;
          }
        }

        .profile_image {
          width: 46px;
          height: 46px;
          object-fit: cover;
          border-radius: 23px;
          box-shadow: 0 0 0 1px $c-gray_light;
        }
      }

      .list-category {
        padding-bottom: 10px;
        padding-top: 10px;

        &:first-of-type {
          margin-top: 10px;
        }

        &:not(:first-of-type) {
          border-top: $border-light;
        }

        .item {
          &.log-out {
            .link,
            .link .icon {
              color: $c-danger;
            }
          }

          .link {
            border: none;
            background: none;
            text-align: left;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 16px 12px;
            margin: 5px 0;
            border-radius: $border-radius;
            border: 1px solid transparent;
            color: $c-black;
            transition: background-color 70ms ease;

            &.active {
              background-color: $c-primary;
              color: $c-white;
              font-weight: 600;
              i {
                color: $c-white;
              }

              &:hover {
                background-color: rgba($color: $c-primary, $alpha: 0.8);
              }

              &:focus {
                background-color: rgba($color: $c-primary, $alpha: 0.8);
                box-shadow: 0 0 0 1px rgba($color: $c-black, $alpha: 0.3) inset;
              }
            }

            &:hover {
              text-decoration: none;
              background-color: rgba($color: $c-black, $alpha: 0.06);
            }

            &:hover,
            &:focus,
            &:active {
              outline: none;
              text-decoration: none;
            }

            &:focus {
              border: 1px solid rgba($color: $c-primary, $alpha: 0.2);
            }

            .text {
              width: 100%;
              font-weight: 500;
              font-size: 14px;
              margin-left: 10px;
            }

            .icon {
              display: flex;
              margin-right: 10px;
              color: $c-primary;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $media-md) {
  .sidebar {
    .content-holder {
      width: $sidebar-tablet-width;
      transform: translateX(-$sidebar-tablet-width);
    }
  }
}

@media (min-width: $media-lg) {
  .sidebar {
    position: fixed;
    left: 0;
    pointer-events: all;
    width: max-content;
    z-index: 11;
    transition: none;
    overflow: hidden;
    overflow-y: hidden;

    .content-holder {
      width: $sidebar-desktop-collapsed-width;
      transform: translateX(0);
      border-right: $border-light;
      transition: none;
      overflow-y: hidden;

      .content {
        .ustella-branding {
          display: flex;
          justify-content: center;
          align-items: center;
          height: max-content;
          margin-top: -30px;
          padding-bottom: 35px;
          border-bottom: $border-light;

          .logo {
            height: 100px;
            width: auto;
          }
        }

        .user {
          display: none;
        }
        .list-category {
          &.profile-items {
            display: none;
          }

          .item {
            .link {
              padding: 12px;
              margin: 12px 0;
              width: max-content;

              .text {
                display: none;
              }
              .icon {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }
    }
    .sidebar__toggler {
      display: none;
    }
  }
}

@media (min-width: $media-xxl) {
  .sidebar {
    width: $sidebar-desktop-expanded-width;

    .content-holder {
      width: $sidebar-desktop-expanded-width;

      .content {
        .list-category {
          .item {
            .link {
              padding: 16px 12px;
              margin: 8px 0;
              width: 100%;

              .text {
                display: block;
              }
              .icon {
                width: unset;
                height: unset;
                margin-right: 5px;
                i {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
