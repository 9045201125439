.sign_out-modal {
  display: none;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;

  &.show {
    display: flex;
    background: $c-white;
    align-items: center;
    justify-content: center;
    z-index: 13;
  }

  .content_holder {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;

    .modal_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .description {
        opacity: 0.7;
      }

      .buttons {
        margin-top: 30px;
        display: flex;

        .btn {
          width: 140px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: none;
          border: none;
          font-size: 16px;
          border-radius: 6px;
          padding: 15px;
          box-shadow: none;
          border: $border-light;

          &.cancel {
            margin-right: 15px;

            &:hover {
              background-color: $c-button-hover;
            }
          }

          &.log-out {
            background-color: $c-primary;
            color: $c-white;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
