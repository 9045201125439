$header-row-height: 60px;
$row-height: 52px;
$filter-area-height: 45px;

.table-wrapper {
  position: relative;
  background: white;
  border-radius: $border-radius-lg;
  // border: $border-light;
  display: block;
  box-shadow: 0 3px 6px 3px rgba($color: #000000, $alpha: 0.04);

  // Header
  // Contains table title, export and fullscreen buttons
  .table-wrapper__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $border-light;

    .table-title {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      padding-left: 15px;
      margin-bottom: -4px;
    }

    .buttons {
      display: flex;
      align-items: center;
      .header-button {
        padding: 15px;
        background: none;
        border: none;
        border-left: $border-light;
        display: flex;
        align-items: center;

        i {
          font-size: 20px;
        }

        &.fullscreen {
          display: none;
        }

        &.export {
          color: $c-primary;
        }

        &:hover {
          background: rgba($color: #000000, $alpha: 0.04);
        }
      }
    }
  }

  // Subheader
  // Contains filtering and pagination
  .table-wrapper__subheader {
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid $c-gray_light;
  }

  // Filters
  .filters {
    display: flex;
    position: relative;
    height: $filter-area-height;

    // Quick search
    .quick-search {
      height: 100%;
      min-width: 150px;
      width: 100%;
      border: none;
      border-radius: 0px;

      input {
        border-bottom: 3px solid transparent;

        &:hover {
          border-bottom: 3px solid rgba($color: $c-primary, $alpha: 0.2);
        }
        &:focus,
        &:not(:placeholder-shown) {
          box-shadow: none;
          border-bottom: 3px solid rgba($color: $c-primary, $alpha: 0.9);
        }
      }
    }

    // Filters button
    .filter-button {
      padding: 15px;
      display: flex;
      align-items: center;
      font-weight: 500;
      border: none;
      background: none;
      border-right: $border-light;

      &.active {
        background: rgba($color: #000000, $alpha: 0.04);
      }

      &:hover {
        background: rgba($color: #000000, $alpha: 0.04);
      }

      .text {
        margin-right: 5px;
      }

      .selected-count {
        background: $c-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        color: $c-white;
        font-size: 14px;
        font-weight: bold;
        border-radius: 30px;
      }

      .icon {
        margin-left: 15px;
      }
    }
    .shortcut-reset-button {
      background: none;
      border: none;
      border-right: $border-light;
      padding: 0 6px;
      display: flex;
      align-items: center;
      background-color: rgba($c-danger, 0.08);
      color: $c-danger;

      &.active {
        background: rgba($c-danger, 0.04);
      }

      &:hover {
        background: rgba($c-danger, 0.15);
      }

      i {
        font-size: 20px;
      }
    }

    // Filters area
    .filters-area {
      position: absolute;
      margin-top: $filter-area-height;
      width: 100%;
      background-color: $c-white;
      display: block;
      opacity: 0;
      height: 0;
      pointer-events: none;
      padding: 15px;
      border-top: $border-light;
      border: $border-light;
      box-shadow: 0 5px 10px 5px rgba($color: $c-primary, $alpha: 0.05);
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;

      &.show {
        opacity: 1;
        height: max-content;
        pointer-events: all;
        z-index: 15;
      }

      .filter-buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  // Pagination
  .pagination {
    margin: 0;
    padding: 0;
    font-size: 14px;
    width: 100%;

    .pg_item {
      padding: 0;
      margin: 0;
      border-top: $border-light;
      height: $filter-area-height;
      display: flex;
      align-items: center;

      &.results {
        opacity: 0.7;
        padding: 0 15px;

        .data {
          margin-right: 3px;
        }
      }
      &.pages-list {
        padding: 0 15px;
      }

      &.show-count-button,
      &.jump-to-button {
        border-left: $border-light;
      }
    }
    .pg-button {
      &__wrapper {
        display: flex;
        width: 100%;

        height: 100%;
        position: relative;
      }
      &__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0px 15px;
        background: none;
        border: none;
        font-size: 14px;

        .right {
          display: flex;
          width: 40px;
          align-items: flex-end;
        }

        .label {
          color: #a19faa;
        }
        .current {
          padding: 0 3px 0 7px;
          font-weight: 500;
        }
        .icon i {
          font-size: 12px;
        }
      }
      &__dropdown {
        position: absolute;
        display: block;
        opacity: 0;
        pointer-events: none;
        max-height: 0px;
        z-index: 2;
        background-color: $c-white;
        overflow-y: scroll;
        width: 100%;
        margin-top: $filter-area-height;
        padding: 8px 0;
        border: $border-light;
        border-bottom-left-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
        box-shadow: 0 3px 6px 3px rgba($color: $c-black, $alpha: 0.08);

        .dropdown_item {
          padding: 6px 15px;
          cursor: pointer;
          font-size: 15px;
          font-weight: 500;
          &:hover {
            background-color: rgba($color: $c-primary, $alpha: 0.05);
          }

          &.selected {
            background-color: rgba($color: $c-primary, $alpha: 1);
            color: $c-white;
          }
        }

        &.show {
          opacity: 1;
          pointer-events: all;
          max-height: 200px;
        }
      }
    }

    .pages-list {
      .pages-ul {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        .pages-li {
          display: inline-block;

          .pages-button,
          .dots {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            margin: 0 1px;
          }
          .dots {
            opacity: 0.4;
          }

          .pages-button {
            background: none;
            border: none;
            border-radius: $border-radius;

            &:disabled {
              cursor: default;
            }

            &.current {
              background-color: $c-primary;
              color: $c-white;
              font-weight: 500;

              &:hover:not(:disabled) {
                background-color: rgba($color: $c-primary, $alpha: 0.86);
              }
            }

            &.chevron {
              i {
                font-size: 13px;
              }
            }

            &:hover:not(:disabled) {
              background-color: rgba($color: $c-primary, $alpha: 0.12);
            }
          }
        }
      }
    }
  }

  // Table itself
  .dt {
    border-collapse: collapse;
    min-width: 100%;
    width: 100%;
    display: table;

    &__header {
      display: table-row;
      min-width: 100%;
      height: 54px;
      z-index: 1;

      .cell-header {
        position: sticky;
        top: $navbar-mobile-height;
        background-color: #eeeeee;
        white-space: nowrap;
        height: 56px;
        padding: 0;
        font-family: $secondary-font;

        &.sorting-by {
          .cell {
            font-weight: 600 !important;
          }
        }

        .cell {
          border: none;
          background: none;
          padding-top: 3px;
          line-height: 0px;
          font-weight: 500 !important;
          font-size: 16px !important;
          color: #1e1e1e !important;
          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.045);
          }
        }

        &:hover {
          .cell-icon.rest {
            .icon.show-on-hover {
              display: block;
            }
          }
        }

        .cell-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;

          &.sorting {
            .icon {
              color: $c-white;
              background-color: $c-primary;
            }
            .icon.icon-sorting {
              display: block;
            }
          }

          .icon {
            border-radius: 30px;
            margin-left: 3px;
            padding: 4px;
            font-size: 20px;

            &.icon-sorting {
              display: none;
            }

            &.show-on-hover {
              display: none;
              opacity: 0.5;
            }
          }
        }
      }
    }

    &__body {
      min-width: 100%;
      display: table;
    }

    &__row {
      cursor: pointer;
      position: relative;
      min-width: 100%;
      display: table-row;
      border-top: $border-light;
      height: 51px;

      &:last-of-type {
        border-bottom: $border-light;
      }

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.02);
      }

      &.mobile-shown {
        .table-cell .cell {
          color: #000;
        }
      }

      &.clickable {
        cursor: pointer;
      }
    }

    &__cell {
      color: #434343;
      display: table-cell;
      margin: 0;
      padding: 0;
      height: inherit;
      scroll-snap-align: end;

      &.highlight-sorted {
        background-color: rgba($color: #000000, $alpha: 0.025);
        .cell {
          color: #1e1e1e;
          -webkit-text-stroke-width: 0.7px;
          -webkit-text-stroke-color: #1e1e1e;
        }
      }

      &.empty-cell {
        opacity: 0.3;
        .cell {
          user-select: none;
        }
      }

      .cell {
        height: inherit;
        font-size: 14px;
        font-weight: 300;
        white-space: nowrap;
        padding: 0 16px;
        display: flex;
        align-items: center;
      }

      &.buttons {
        display: none;
      }
    }

    &__mobile-area {
      cursor: default;
      position: absolute;
      left: 0;
      margin-top: 50px;
      transform: translateY(-10px) scale(1.02);
      width: 100%;
      padding-bottom: 30px;
      height: 0;
      opacity: 0;
      pointer-events: none;
      transition: transform 60ms ease-out, opacity 60ms ease-out;

      &.show {
        height: max-content;
        transform: translateY(0px) scale(1.02);
        opacity: 1;
        pointer-events: all;
        z-index: 2;

        transition: transform 220ms cubic-bezier(0.23, 1, 0.32, 1),
          opacity 150ms cubic-bezier(0.19, 1, 0.22, 1);
      }

      .mobile-content {
        // transform: scale(1.02);
        position: relative;
        border-radius: $border-radius-lg;
        background-color: $c-white;
        padding: 15px 15px 0 15px;
        box-shadow: 0 0 0 1px $c-gray_light,
          0 3px 6px 3px rgba($color: $c-primary, $alpha: 0.1);
        font-size: 15px;

        .content-item {
          margin-bottom: 15px;
          .title {
            display: block;
            font-size: 11px;
            margin-bottom: -2px;
            font-weight: 300;
            opacity: 0.7;
          }
          .data {
            display: block;
            width: 100%;
            margin-right: 40px;
          }
        }

        .mobile-button {
          margin-bottom: 15px;
          font-size: 14px;

          i {
            margin-right: 8px;
            font-size: 16px;
          }
        }
      }
    }

    &__empty-table {
      display: block;

      width: 100%;

      .table-content {
        display: block;
        width: 100%;

        .loading {
          display: block;
          height: calc(#{$header-row-height} * 10);
          width: 100%;
          background-color: $c-loader-background;

          .loader {
            height: 100%;
            width: 100%;
          }
        }

        .error,
        .empty {
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px;
          text-align: center;

          span {
            font-size: 14px;
            opacity: 0.8;
            font-weight: 500;
          }
        }
      }
    }
  }

  // Overflowing
  &.overflowing {
    .dt-container {
      overflow-x: scroll;
      overflow-y: visible;
      scroll-snap-type: x mandatory;
    }
    .dt {
      &__header {
        .cell-header {
          top: 0;
        }
      }

      &__cell {
        &:first-of-type {
          position: sticky;
          left: 0;
          z-index: 1;
          font-weight: 500;
          background-color: $c-white;
          color: #141313;

          &:after {
            content: " ";
            height: 100%;
            position: absolute;
            top: 0;
            width: 30px;
            background: linear-gradient(
              -90deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.035) 100%
            );

            right: -30px;
          }
        }

        &.buttons {
          background-color: $c-white;
          &:after {
            content: " ";
            height: 100%;
            position: absolute;
            top: 0;
            width: 30px;
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.035) 100%
            );

            left: -30px;
          }
        }
      }
    }
  }
}

@media (min-width: $media-md) {
  .table-wrapper {
    position: relative;
    height: 100%;

    // Subheader
    .table-wrapper__subheader {
      display: flex;
      justify-content: space-between;
      border-bottom: 3px solid $c-gray_light;

      .pagination {
        border-top: $border-light;
        width: 100%;
        .pg_item {
          border-top: none;
          border-left: $border-light;
          max-width: max-content;
        }
      }
      .filters {
        .quick-search {
          min-width: 180px;
        }
      }
    }

    // Fullscreen
    &.fullscreen {
      position: fixed;
      z-index: 9999;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      overflow-x: hidden;
      overflow-y: scroll;

      .datatable {
        &__header {
          .cell-header {
            top: 0;
          }
        }
      }
    }

    .table-wrapper__header .buttons .header-button.fullscreen {
      display: flex;
    }

    .dt {
      &__row {
        cursor: default;
      }

      &__cell {
        &:last-of-type {
          padding-right: 30px;
        }

        &.buttons {
          display: none;
          position: absolute;
          right: 0;
          padding: 0;
          height: inherit;
          z-index: 1;

          &.show {
            display: flex;
          }

          .button-content {
            height: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            padding-left: 5px;

            &:before {
              content: " ";
              position: absolute;
              top: 0;
              width: 30px;
              background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.05) 100%
              );

              left: -30px;
            }
          }

          .vertical-button {
            pointer-events: all;
            border: none;
            background: none;
            color: $c-primary;

            border-radius: 50px;
            margin-right: 5px;
            width: 24px;
            height: 36px;

            &:disabled {
              opacity: 0.3;
              cursor: default;
            }

            i {
              font-size: 20px;
            }

            &:hover {
              .button-tooltip {
                opacity: 1;
              }
            }
          }

          .button-tooltip {
            opacity: 0;
            pointer-events: none;
            display: block;
            position: absolute;
            margin-left: -35px;
            z-index: 1;
            margin-top: 9px;
            font-size: 9px;
            background-color: #53515a;
            padding: 3px 7px;
            border-radius: $border-radius;
            color: $c-white;
            width: max-content;
            transition: opacity 80ms ease;
          }

          .buttons-area {
            opacity: 0;
            pointer-events: none;

            position: absolute;
            z-index: 1;

            right: -10px;
            top: -10px;
            width: max-content;
            background: $c-white;
            box-shadow: 0 3px 6px 3px rgba($color: $c-black, $alpha: 0.15);
            border: 1px solid rgba($color: $c-black, $alpha: 0.15);
            border-radius: $border-radius-lg;
            padding: 8px 0;

            &.show {
              opacity: 1;
              pointer-events: all;
            }

            .row-button {
              display: flex;
              align-content: center;
              justify-content: flex-start;
              margin: 1px;
              background: none;
              border: none;
              font-weight: 400 !important;
              font-size: 14px;

              padding: 8px 20px 8px 8px;
              font-weight: 500;
              width: 100%;

              i {
                font-size: 20px;
                margin-top: 2px;
                margin-right: 8px;
                opacity: 0.5;
              }

              &:hover {
                background-color: rgba($color: #000000, $alpha: 0.05);
              }
            }
          }
        }
      }

      &__mobile-area {
        &.show {
          display: none;
        }
      }
    }

    .bottom-paginate {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      border-top: $border-light;
      .pagination {
        max-width: max-content;

        .pg_item {
          border-top: none !important;
        }
      }
    }
  }
}

@media (min-width: $media-lg) {
  .table-wrapper {
    .dt {
      &__header {
        .cell-header {
          top: $navbar-desktop-lg-height;
        }
      }
    }
  }
}

@media (min-width: $media-xl) {
  .table-wrapper {
    // Subheader
    .table-wrapper__subheader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 3px solid $c-gray_light;

      .pagination {
        max-width: max-content;
        border-top: none;
      }
      .filters {
        .quick-search {
          min-width: 180px;
          max-width: 300px;
        }
      }
    }

    .filters {
      .filters-area {
        width: 700px;
      }
    }
  }
}
