.container.maintenance {
  margin-bottom: 50px;
  .stats {
    .stats-content {
      border: $border-light;
      border-radius: $border-radius-lg;
      background: $c-white;
      box-shadow: 0 2px 4px 2px rgba($c-black, 0.04);

      &.loading {
        height: 189px;
        background: $c-loader-background;
      }
    }
    .total-stats {
      width: 100%;
      .stats-wrapper {
        .content {
          padding: 15px 20px 7px 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .text {
            .title {
              font-weight: 500;
              font-size: 16px;
            }

            .amount {
              margin-top: -5px;
              font-weight: 500;
              font-size: 28px;
              color: $c-primary;
            }
          }
          .icon {
            color: $c-primary;
            i {
              font-size: 28px;
            }
          }
        }

        .divider {
          flex: 1;
          display: block;
          margin: 0 15px;
          border-bottom: $border-light;
        }
      }
    }

    .stat-types {
      display: flex;
    }

    .stat-card {
      width: 100%;
      margin-bottom: 15px;

      &:not(:first-of-type) {
        border-left: $border-light;
      }

      &.pending {
        color: $c-pending;
      }

      &.cancel {
        color: $c-danger;
      }

      &.success {
        color: $c-success;
      }

      .content {
        padding: 15px 15px 0 15px;

        .title {
          font-weight: 400;
          font-size: 13px;
          opacity: 0.8;
          color: $c-black;
        }

        .amount {
          font-weight: 500;
          font-size: 20px;
        }

        .icon {
          i {
            font-size: 18px;
          }
        }
      }
    }
  }

  .search {
    margin-top: 25px;
  }

  .maintenance-datatable {
    margin-top: 30px;
    padding-bottom: 45px;

    .table-card {
      position: relative;
      border: $border-light;
      border-radius: $border-radius-lg;
      box-shadow: 0 2px 4px 2px rgba($c-black, 0.04);
      background: $c-white;

      .title {
        padding: 15px 15px 5px 15px;

        .title-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 14px;
        border-radius: 20px;
        color: $c-white;
        font-weight: 500;
        // padding: 2px 10px;
        margin-top: 5px;
        font-size: 0;
        height: 16px;
        width: 16px;

        &.pending {
          background: $c-pending;
        }
        &.done {
          background: $c-success;
        }
        &.cancelled {
          background: $c-danger;
        }
      }

      .maintenance-mobile-area {
        display: block;
        width: 100%;
        padding: 15px;
        z-index: 1;

        .title,
        .data {
          display: block;
        }

        .title {
          font-weight: 500;
          opacity: 0.6;
          font-size: 13px;
          margin-left: -15px;
        }
        .data {
          margin-top: -5px;
          font-weight: 600;
        }
      }
    }
  }

  .case-details {
    border: $border-light;
    border-radius: $border-radius-lg;
    background: $c-white;
    box-shadow: 0 2px 4px 2px rgba($c-black, 0.04);

    &.loading {
      background: $c-loader-background;
      height: 300px;
      &.header {
        padding: 0;
        height: 76px;
        background: $c-loader-background;
      }
      &.building {
        height: 280px;
        background: $c-loader-background;
      }

      &.history {
        height: 200px;
        background: $c-loader-background;
      }
    }

    .header {
      padding: 15px;

      position: relative;

      border-bottom: $border-light;

      .description {
        margin-top: 15px;
      }

      span {
        display: block;
        font-size: 15px;
        opacity: 0.7;
        font-weight: 500;
      }

      .status {
        color: $c-white;
        padding: 4px 12px;
        font-weight: 500;
        font-size: 15px;
        width: max-content;
        float: right;

        border-radius: $border-radius-lg;

        &.pending {
          background-color: $c-pending;
        }

        &.cancelled {
          background-color: $c-danger;
        }

        &.done {
          background-color: $c-success;
        }
      }
    }

    .building-details {
      padding: 15px;

      .item {
        margin-bottom: 15px;
        .title {
          display: block;
          font-size: 13px;
          opacity: 0.7;
          font-weight: 500;
        }

        .data {
          font-weight: 600;
        }

        &.closure-notes {
          padding-top: 15px;
          border-top: $border-light;
        }
      }
    }

    .closure-notes {
      border: $border-light;
      padding: 15px;
      margin: 15px;
      margin-top: -10px;
      width: calc(100% - 30px);
      border-radius: $border-radius-lg;
      background: rgba($c-primary, 0.08);

      .title {
        font-weight: 500;
        display: block;
      }
    }

    .case-history {
      border-top: $border-light;
      padding: 15px;
      .item {
        margin-bottom: 15px;
        .created-at {
          font-size: 13px;
          font-weight: 600;
          opacity: 0.8;
          letter-spacing: -0.5px;
          display: block;
        }
      }
    }
  }
}
@media (min-width: $media-md) {
  .container.maintenance {
    .maintenance-datatable {
      .table-card {
        .status {
          font-size: 14px;
          padding: 2px 10px;
          margin-top: auto;
          height: auto;
          width: max-content;
        }
      }

      .maintenance-mobile-area {
        .hideOnTablet {
          display: none;
        }
      }
    }
  }
}

@media (min-width: $media-lg) {
  .container.maintenance {
    .stats {
      .stats-content {
        display: flex;

        &.loading {
          height: 103px;
        }
      }
      .total-stats {
        width: 40%;
        .stats-wrapper {
          border-right: $border-light;
          border-width: 4px;
          box-shadow: 10px 0 20px 10px rgba($c-black, 0.04);

          .content {
            padding: 15px 25px 20px 20px;
            display: flex;
            align-items: center;

            .text {
              .title {
                margin-top: 5px;
              }
              .amount {
                margin: 0;
                margin-top: -8px;
                font-size: 30px;
              }
            }
          }
          .divider {
            display: none;
          }
        }
      }

      .stat-types {
        width: 60%;
      }
      .stat-card {
        margin-bottom: 0;
      }

      .stat-card {
        .content {
          height: 100%;
          position: relative;
          padding: 20px;
          display: flex;
          align-items: center;

          .title {
            font-weight: 400;
            font-size: 14px;
            opacity: 0.8;
            color: $c-black;
          }

          .amount {
            margin-top: -3px;
            font-weight: 500;
            font-size: 22px;
          }

          .icon {
            position: absolute;
            right: 20px;
            i {
              font-size: 22px;
            }
          }
        }
      }
    }

    .case-details {
      padding: 15px;

      &.loading {
        padding: 0;
        overflow: hidden;
        &.history {
          height: 400px;
        }
      }

      .header {
        padding-bottom: 30px;
        margin-bottom: 20px;
      }

      .building-details {
        border-bottom: none;
        margin: 15px;
        border: $border-light;
        border-radius: $border-radius-lg;
        box-shadow: 0 0px 0px 4px rgba($c-primary, 0.08);

        .item {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .closure-notes {
        border: $border-light;
        border-radius: $border-radius-lg;
        box-shadow: 0 0px 0px 4px rgba($c-primary, 0.08);
        background: $c-white;
        padding: 15px;
        margin-top: 20px;
      }

      .case-history {
        border-top: none;
      }
    }
  }
}
