body {
  color: $c-body;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 600;
  color: $c-black;
}
h6 {
  font-size: 14px;
}

a {
  color: $c-primary;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 0;
  font-size: 15px;
}
