.container.auth {
  height: max-content;
  max-width: 540px;

  .divider {
    display: block;
    height: 1px;
    width: 100%;
    border-bottom: $border-light;
  }

  .title {
    padding-top: 30px;
    text-align: center;
    padding-bottom: $padding;

    .logo {
      height: 80px;
    }
    .header {
      margin-top: 20px;
    }
  }

  .continue-as {
    .continue-link {
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        transition: margin-right 120ms cubic-bezier(0.23, 1, 0.32, 1);
      }

      &:hover {
        .icon {
          margin-right: 0px;
        }
      }
    }
  }

  .button.auth.submit {
    font-size: 17px;
    font-weight: 500;
  }

  .error {
    text-align: center;
    font-weight: 500;
    color: $c-error;
  }

  .anchor {
    margin-top: 15px;
    text-align: center;
  }

  .password-utils {
    .show-password {
      display: flex;
      align-items: center;
      position: relative;
    }
    .forgot {
      text-align: right;
    }
  }

  &.submitting {
    .form {
      user-select: none;
      opacity: 0.5;
    }
  }
}

.auth__page {
  .auth__card {
    margin-top: 30px;
    margin-bottom: 40px;

    .form {
      .password-info {
        margin-top: 3px;
        font-size: 11px;
        opacity: 0.7;
      }
    }

    .reset-password {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 15px 0;
      font-size: 13px;
      font-weight: 500;

      .text {
        max-width: 350px;
      }
    }

    .error {
      display: block;
      text-align: center;
      margin: 15px 0;

      .message {
        font-size: 13px;
        font-weight: 500;
        color: $c-error;
      }
    }

    .password-utils {
      height: 40px;
      display: flex;
      align-items: center;

      .show {
        font-size: 13px;
      }

      .forgot {
        text-align: right;
        a {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }

    .links {
      &.signup,
      &.login {
        margin-top: 20px;
        text-align: center;
        a {
          font-weight: 500;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .wrapper.auth {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container.auth {
    background: $c-white;
    border: $border-light;
    border-radius: $border-radius-lg;
    padding: 30px 40px 50px 40px;
  }
}
